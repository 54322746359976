import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
import { PopularTitleGraphqlFragmentDoc } from '../fragments/PopularTitle.fragment';
import {
	TitleOfferFragmentDoc,
	FastOfferFragmentDoc,
} from '../../../components/buybox/graphql/fragments/Offer.fragment';
export type GetPopularTitlesQueryVariables = Types.Exact<{
	backdropProfile?: Types.InputMaybe<Types.BackdropProfile>;
	country: Types.Scalars['Country']['input'];
	first?: Types.Scalars['Int']['input'];
	format?: Types.InputMaybe<Types.ImageFormat>;
	language: Types.Scalars['Language']['input'];
	after?: Types.InputMaybe<Types.Scalars['String']['input']>;
	popularTitlesFilter?: Types.InputMaybe<Types.TitleFilter>;
	popularTitlesSortBy?: Types.PopularTitlesSorting;
	profile?: Types.InputMaybe<Types.PosterProfile>;
	sortRandomSeed?: Types.Scalars['Int']['input'];
	watchNowFilter: Types.WatchNowOfferFilter;
	offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
	creditsRole?: Types.CreditRole;
}>;

export type GetPopularTitlesQuery = {
	__typename?: 'Query';
	popularTitles: {
		__typename: 'PopularTitlesConnection';
		totalCount: number;
		edges?: Array<{
			__typename?: 'PopularTitlesEdge';
			cursor: string;
			node:
				| {
						__typename: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						freeOffersCount: number;
						seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						content: {
							__typename?: 'MovieContent';
							title: string;
							fullPath: string;
							posterUrl?: string | null;
							isReleased: boolean;
							runtime?: number | null;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							scoring: {
								__typename?: 'Scoring';
								imdbVotes?: number | null;
								imdbScore?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
								jwRating?: number | null;
							};
							interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
							dailymotionClips: Array<{
								__typename?: 'Clip';
								sourceUrl: string;
								externalId: string;
								provider: Types.ClipProvider;
							}>;
							credits: Array<{ __typename?: 'Credit'; name: string; personId: number }>;
							genres: Array<{ __typename?: 'Genre'; translation: string; shortName: string }>;
						};
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
				  }
				| {
						__typename: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						freeOffersCount: number;
						tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number; progress: number };
						content: {
							__typename?: 'ShowContent';
							title: string;
							fullPath: string;
							posterUrl?: string | null;
							isReleased: boolean;
							runtime?: number | null;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							scoring: {
								__typename?: 'Scoring';
								imdbVotes?: number | null;
								imdbScore?: number | null;
								tmdbPopularity?: number | null;
								tmdbScore?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
								jwRating?: number | null;
							};
							interactions: { __typename?: 'InteractionAttributes'; votesNumber: number };
							dailymotionClips: Array<{
								__typename?: 'Clip';
								sourceUrl: string;
								externalId: string;
								provider: Types.ClipProvider;
							}>;
							credits: Array<{ __typename?: 'Credit'; name: string; personId: number }>;
							genres: Array<{ __typename?: 'Genre'; translation: string; shortName: string }>;
						};
						likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
						watchNowOffer?: {
							__typename: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							lastChangeRetailPriceValue?: number | null;
							currency?: string | null;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							availableTo?: any | null;
							dateCreated?: any | null;
							newElementCount?: number | null;
							package: {
								__typename?: 'Package';
								id: string;
								icon: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								iconWide: string;
								hasRectangularIcon: boolean;
							};
						} | null;
				  };
		}> | null;
		pageInfo: {
			__typename?: 'PageInfo';
			startCursor: string;
			endCursor: string;
			hasPreviousPage: boolean;
			hasNextPage: boolean;
		};
	};
};

export type GetPopularMetaDataQueryVariables = Types.Exact<{
	fullPath: Types.Scalars['String']['input'];
}>;

export type GetPopularMetaDataQuery = {
	__typename?: 'Query';
	metaData: { __typename?: 'Url'; id: string; metaTitle?: string | null; metaDescription?: string | null };
};

export type GetQtvPopularTitlesQueryVariables = Types.Exact<{
	backdropProfile?: Types.InputMaybe<Types.BackdropProfile>;
	country: Types.Scalars['Country']['input'];
	first?: Types.Scalars['Int']['input'];
	format?: Types.InputMaybe<Types.ImageFormat>;
	language: Types.Scalars['Language']['input'];
	after?: Types.InputMaybe<Types.Scalars['String']['input']>;
	popularTitlesFilter?: Types.InputMaybe<Types.TitleFilter>;
	popularTitlesSortBy?: Types.PopularTitlesSorting;
	profile?: Types.InputMaybe<Types.PosterProfile>;
	sortRandomSeed?: Types.Scalars['Int']['input'];
	offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
	offerFilters?: Types.InputMaybe<Types.OfferFilter>;
}>;

export type GetQtvPopularTitlesQuery = {
	__typename?: 'Query';
	popularTitles: {
		__typename: 'PopularTitlesConnection';
		edges?: Array<{
			__typename?: 'PopularTitlesEdge';
			node:
				| {
						__typename: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'MovieContent';
							title: string;
							fullPath: string;
							posterUrl?: string | null;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							scoring: {
								__typename?: 'Scoring';
								imdbVotes?: number | null;
								imdbScore?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
								jwRating?: number | null;
							};
						};
						offers: Array<{
							__typename?: 'Offer';
							id: string;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							newElementCount?: number | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							currency?: string | null;
							lastChangeRetailPriceValue?: number | null;
							type: Types.OfferType;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							elementCount?: number | null;
							availableTo?: any | null;
							subtitleLanguages: Array<any>;
							videoTechnology: Array<Types.VideoTechnology>;
							audioTechnology: Array<Types.AudioTechnology>;
							audioLanguages: Array<any>;
							package: {
								__typename?: 'Package';
								id: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								icon: string;
								iconWide: string;
								planOffers: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
									children: Array<{
										__typename?: 'PackagePlanOffer';
										title?: string | null;
										retailPrice?: string | null;
										isTrial?: boolean | null;
										durationDays?: number | null;
										retailPriceValue?: number | null;
									}>;
								}>;
							};
						}>;
				  }
				| {
						__typename: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'ShowContent';
							title: string;
							fullPath: string;
							posterUrl?: string | null;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							scoring: {
								__typename?: 'Scoring';
								imdbVotes?: number | null;
								imdbScore?: number | null;
								tomatoMeter?: number | null;
								certifiedFresh?: boolean | null;
								jwRating?: number | null;
							};
						};
						offers: Array<{
							__typename?: 'Offer';
							id: string;
							presentationType: Types.PresentationType;
							monetizationType: Types.MonetizationType;
							newElementCount?: number | null;
							retailPrice?: string | null;
							retailPriceValue?: number | null;
							currency?: string | null;
							lastChangeRetailPriceValue?: number | null;
							type: Types.OfferType;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
							streamUrl?: string | null;
							elementCount?: number | null;
							availableTo?: any | null;
							subtitleLanguages: Array<any>;
							videoTechnology: Array<Types.VideoTechnology>;
							audioTechnology: Array<Types.AudioTechnology>;
							audioLanguages: Array<any>;
							package: {
								__typename?: 'Package';
								id: string;
								packageId: number;
								clearName: string;
								shortName: string;
								technicalName: string;
								icon: string;
								iconWide: string;
								planOffers: Array<{
									__typename?: 'PackagePlanOffer';
									title?: string | null;
									retailPrice?: string | null;
									isTrial?: boolean | null;
									durationDays?: number | null;
									retailPriceValue?: number | null;
									children: Array<{
										__typename?: 'PackagePlanOffer';
										title?: string | null;
										retailPrice?: string | null;
										isTrial?: boolean | null;
										durationDays?: number | null;
										retailPriceValue?: number | null;
									}>;
								}>;
							};
						}>;
				  };
		}> | null;
	};
};

export const GetPopularTitlesDocument = gql`
	query GetPopularTitles(
		$backdropProfile: BackdropProfile
		$country: Country!
		$first: Int! = 70
		$format: ImageFormat
		$language: Language!
		$after: String
		$popularTitlesFilter: TitleFilter
		$popularTitlesSortBy: PopularTitlesSorting! = POPULAR
		$profile: PosterProfile
		$sortRandomSeed: Int! = 0
		$watchNowFilter: WatchNowOfferFilter!
		$offset: Int = 0
		$creditsRole: CreditRole! = DIRECTOR
	) {
		popularTitles(
			country: $country
			filter: $popularTitlesFilter
			first: $first
			sortBy: $popularTitlesSortBy
			sortRandomSeed: $sortRandomSeed
			offset: $offset
			after: $after
		) {
			__typename
			edges {
				cursor
				node {
					...PopularTitleGraphql
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasPreviousPage
				hasNextPage
			}
			totalCount
		}
	}
	${PopularTitleGraphqlFragmentDoc}
`;
export const GetPopularMetaDataDocument = gql`
	query GetPopularMetaData($fullPath: String!) {
		metaData: urlV2(fullPath: $fullPath) {
			id
			metaTitle
			metaDescription
		}
	}
`;
export const GetQtvPopularTitlesDocument = gql`
	query GetQTVPopularTitles(
		$backdropProfile: BackdropProfile
		$country: Country!
		$first: Int! = 8
		$format: ImageFormat
		$language: Language!
		$after: String
		$popularTitlesFilter: TitleFilter
		$popularTitlesSortBy: PopularTitlesSorting! = POPULAR_7_DAYS
		$profile: PosterProfile
		$sortRandomSeed: Int! = 0
		$offset: Int = 0
		$offerFilters: OfferFilter = { packages: ["atp"] }
	) {
		popularTitles(
			country: $country
			filter: $popularTitlesFilter
			first: $first
			sortBy: $popularTitlesSortBy
			sortRandomSeed: $sortRandomSeed
			offset: $offset
			after: $after
		) {
			__typename
			edges {
				node {
					__typename
					id
					objectId
					objectType
					content(country: $country, language: $language) {
						title
						fullPath
						scoring {
							imdbVotes
							imdbScore
							tomatoMeter
							certifiedFresh
							jwRating
						}
						posterUrl(profile: $profile, format: $format)
						... on MovieOrShowOrSeasonContent {
							backdrops(profile: $backdropProfile, format: $format) {
								backdropUrl
							}
						}
					}
					offers(country: $country, platform: WEB, filter: $offerFilters) {
						...TitleOffer
					}
				}
			}
		}
	}
	${TitleOfferFragmentDoc}
`;
